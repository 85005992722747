<aside class="fixed top-0 left-0 z-20 w-64 h-screen pt-14 transition-transform bg-gray translate-x-0 border-r border-secondary" aria-label="Sidenav"
	id="drawer-navigation">
	<div class="overflow-y-auto py-5 px-3 h-full bg-gray">
		<ul class="space-y-2">
			<li>

			</li>
		</ul>
		<!-- <ul class="space-y-2 pt-2 mt-2 border-t border-secondary">
			<li>
				<app-button [width]="'full'" [style]="'text'" link="/users" text="Users"></app-button>
			</li>
		</ul> -->
	</div>
	<div class="absolute bottom-0 left-0 justify-center p-4 space-x-4 w-full lg:flex bg-gray z-20">
		version
	</div>
</aside>