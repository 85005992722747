import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { timer, BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class VersionService {
	public version: any;

	private newVersionSubject = new BehaviorSubject<boolean>(false);

	constructor(private http: HttpClient) {
		this.version = '';
	}

	fetchVersion(): Promise<void> {

		const httpOptions = {
			headers: new HttpHeaders({
				'Cache-Control': 'no-cache'
			})
		};

		return new Promise((resolve) => {
			this.http.get('assets/version.json', httpOptions).toPromise().then((response: any) => {
				console.log('response', response);
				this.version = response.version;
				resolve();
			});
		});
	}

	public get newVersion(): Observable<boolean> {
		return this.newVersionSubject;
	}

	pollVersion() {
		const pollInterval = 3600000;

		const httpOptions = {
			headers: new HttpHeaders({
				'Cache-Control': 'no-cache'
			})
		};

		timer(pollInterval, pollInterval).subscribe(() => {
			this.http.get('assets/version.json', httpOptions).subscribe((response: any) => {
				const newVersion = response.version;
				if (this.version !== newVersion) {
					this.newVersionSubject.next(true);
				}
			});
		});
	}

}
