import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
	selector: 'app-device-widget',
	templateUrl: './device-widget.component.html',
	styleUrls: ['./device-widget.component.scss']
})
export class DeviceWidgetComponent implements OnInit, OnChanges {

	left: string = '';
	current: number = -10;
	interval: any = null;

	@Input() progress: boolean = false;
	@Input() disabled: boolean = false;
	@Input() widgetName: string = '';
	@Input() widgetSubName: string = '';

	constructor() { }

	ngOnInit(): void {

		this.left = `left: ${this.current}%`;
	}

	ngOnChanges(changes: SimpleChanges) {
		// console.log(changes);
		if (changes.hasOwnProperty('progress') && changes['progress'].currentValue) {
			if (this.interval) clearInterval(this.interval);

			this.interval = setInterval(() => {
				if (this.current == 100) this.current = -10;


				this.current += 1;
				this.left = `left: ${this.current}%`;

			}, 25);
		} else {
			if (this.interval) clearInterval(this.interval);
		}
	}
}
