<nav class="border-b border-secondary px-4 py-2.5 fixed left-0 right-0 top-0 z-30 bg-gray">
	<div class="flex flex-wrap justify-between items-center">
		<div class="flex justify-start items-center">
			<a href="" class="flex items-center justify-between mr-4">
				<img src="./assets/img/ai_pilot_logo.svg" class="mr-3 h-10" alt="AI Pilot Logo" />
				<!-- <span class="self-center text-2xl font-semibold whitespace-nowrap">AI Pilot</span> -->
			</a>
		</div>
		<div class="flex items-center gap-2 lg:order-2">
			<p-dropdown [options]="gateways" [ngModel]="gateway" (ngModelChange)="gatewayChanged($event)" optionLabel="name"
				placeholder="Select a gateway" [filter]="true" filterBy="name" emptyMessage="No Gateways"></p-dropdown>
			<p-button (click)="isOpen = true" title="open" styleClass="p-button-text">
				<fa-icon size="xl" [icon]="['fas', 'bars']"></fa-icon>
			</p-button>
		</div>
	</div>
</nav>
<p-sidebar [(visible)]="isOpen" position="right" styleClass="!w-10/12">
	<ng-template pTemplate="closeicon">
		<fa-icon size="xl" [icon]="['fas', 'xmark']"></fa-icon>
	</ng-template>
	<ul class="space-y-2">
		<li>
			<a pButton routerLink="dashboard" routerLinkActive #rlaDashbaord="routerLinkActive" class="w-full" (click)="isOpen = false"
				[ngClass]="{'': rlaDashbaord.isActive ,'p-button-text': !rlaDashbaord.isActive }">Dashboard</a>
		</li>
		<li>
			<a pButton routerLink="devices" routerLinkActive #rlaDevices="routerLinkActive" class="w-full" (click)="isOpen = false"
				[ngClass]="{'': rlaDevices.isActive ,'p-button-text': !rlaDevices.isActive }">Devices</a>
		</li>
		<li>
			<a pButton routerLink="rules" routerLinkActive #rlaRules="routerLinkActive" class="w-full" (click)="isOpen = false"
				[ngClass]="{'': rlaRules.isActive ,'p-button-text': !rlaRules.isActive }">Rules</a>
		</li>
		<li>
			<a pButton routerLink="events" routerLinkActive #rlaEvents="routerLinkActive" class="w-full" (click)="isOpen = false"
				[ngClass]="{'': rlaEvents.isActive ,'p-button-text': !rlaEvents.isActive }">Events</a>
		</li>
		<li>
			<a pButton routerLink="integrations" routerLinkActive #rlaIntegrations="routerLinkActive" class="w-full" (click)="isOpen = false"
				[ngClass]="{'': rlaIntegrations.isActive ,'p-button-text': !rlaIntegrations.isActive }">Integrations</a>
		</li>
		<li>
			<a pButton routerLink="settings" routerLinkActive #rlaSettings="routerLinkActive" class="w-full" (click)="isOpen = false"
				[ngClass]="{'': rlaSettings.isActive ,'p-button-text': !rlaSettings.isActive }">Settings</a>
		</li>
		<li>
			<a pButton class="w-full" href="https://auth.iot.aipilot.tech/settings" class="p-button-text" target="_blank">
				<fa-icon [icon]="['fas', 'up-right-from-square']"></fa-icon>
				<span class="ml-2">Account Settings</span>
			</a>
		</li>
	</ul>
	<p-divider></p-divider>
	<ul class="space-y-2">
		<li>
			<p-button styleClass="w-full p-button-text text-left" (onClick)="logout()">
				<fa-icon [icon]="['fas', 'arrow-right-from-bracket']"></fa-icon>
				<span class="ml-2">Logout</span>
			</p-button>
		</li>
	</ul>
</p-sidebar>
<div *ngIf="gateway_switching" class="p-component-overlay z-[100] flex justify-center items-center">
	<div class="flex flex-col gap-2 items-center">
		<fa-icon size="xl" [icon]="['fas', 'spinner']" [spin]="true"></fa-icon>
		<p>Switching gateway...</p>
	</div>
</div>