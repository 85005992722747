import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class ApiService {

	constructor(private http: HttpClient) { }

	public get(url: string, options?: any) {
		// console.log('options && options.headers', options && options.headers);
		const headers = options && options.headers ? options.headers.append('x-app-name', 'user-interface') : new HttpHeaders().set('x-app-name', 'user-interface');
		// console.log('headers', headers);
		if (options) {
			options['headers'] = headers;
		} else {
			options = {};
			options['headers'] = headers;
		}

		return this.http.get(url, options);
	}

	public post(url: string, data: any, options?: any) {
		// console.log('options && options.headers', options && options.headers);
		const headers = options && options.headers ? options.headers.append('x-app-name', 'user-interface') : new HttpHeaders().set('x-app-name', 'user-interface');
		// console.log('headers', headers);
		if (options) {
			options['headers'] = headers;
		} else {
			options = {};
			options['headers'] = headers;
		}

		return this.http.post(url, data, options);
	}

	public put(url: string, data: any, options?: any) {
		// console.log('options && options.headers', options && options.headers);
		const headers = options && options.headers ? options.headers.append('x-app-name', 'user-interface') : new HttpHeaders().set('x-app-name', 'user-interface');
		// console.log('headers', headers);
		if (options) {
			options['headers'] = headers;
		} else {
			options = {};
			options['headers'] = headers;
		}

		return this.http.put(url, data, options);
	}

	public delete(url: string, options?: any) {
		// console.log('options && options.headers', options && options.headers);
		const headers = options && options.headers ? options.headers.append('x-app-name', 'user-interface') : new HttpHeaders().set('x-app-name', 'user-interface');
		// console.log('headers', headers);
		if (options) {
			options['headers'] = headers;
		} else {
			options = {};
			options['headers'] = headers;
		}

		return this.http.delete(url, options);
	}
}
