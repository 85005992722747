<div class="rounded-lg flex flex-col p-3 min-h-full h-44 relative overflow-hidden" [ngClass]="{'bg-[#F8FAFC]': disabled, 'bg-gray': !disabled}">
	<div class="flex flex-row items-center">
		<div class="shrink-0 pr-2" [ngClass]="{'opacity-50': disabled}">
			<img class="h-8" src="./assets/img/ai_pilot_logo.svg" height="32px" />
		</div>
		<div class="min-w-0" [ngClass]="{'opacity-50': disabled}">
			<div class="text-primary opacity-50 text-xs text-ellipsis overflow-hidden whitespace-nowrap" [title]="widgetSubName">
				{{widgetSubName}}
			</div>
			<div class="font-medium text-primary text-sm text-ellipsis overflow-hidden whitespace-nowrap" [title]="widgetName">
				{{widgetName}}
			</div>
		</div>
		<div class="ml-auto flex-shrink flex items-center gap-1 pl-2">
			<ng-content select="[widgetoptions]"></ng-content>
		</div>
	</div>
	<div class="flex-grow py-1.5" [ngClass]="{'opacity-50': disabled}">
		<ng-content></ng-content>
	</div>
	<div class="mt-auto leading-4" [ngClass]="{'opacity-50': disabled}">
		<ng-content select="[widgetfooter]"></ng-content>
	</div>
	<div *ngIf="progress" class="absolute left-0 right-0 bottom-0 h-2 bg-primary-lighter">
		<div class="absolute bg-primary h-2 rounded-full w-10" [style]="left"></div>
	</div>
</div>